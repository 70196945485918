@value text_secondary from '../../styles/styles.module.css';
.container {
    display: flex;
    justify-content: center;
    text-align: center;
    color: text_secondary;
    height: 11rem;
    margin-top: 5rem;
}

.container a {
    color: text_secondary;
}