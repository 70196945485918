@value background_secondary, theme_yellow from '../../styles/styles.module.css';
@value tablet from '../../styles/styles.module.css';
.container {
    padding-top: 8rem;
    padding-bottom: 6rem;
    height: 40rem;
    display: flex;
    flex-direction: row;
}

.text_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 60%;
    background-color: background_secondary;
    padding: 2rem;
}

.container a {
    color: theme_yellow;
}

.coffee_image {
    width: 40%;
    object-fit: cover;
}

@media(max-width:tablet) {
    .container {
        flex-direction: column-reverse;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 45rem;
    }
    .text_container {
        width: 100%;
        height: 100%;
    }
    .coffee_image {
        width: 100%;
        height: 15rem;
    }
}