/* Colors */

@value background_primary: #1d1d1d;
@value background_secondary: #2b2b2b;
@value text_primary: #fff;
@value text_secondary: #848484;
@value theme_gray: #848484;
@value theme_white: #fff;
@value theme_green: #01c853;
@value theme_blue: #2879ff;
@value theme_yellow: #fec107;
@value theme_orange: #ff8f00;
@value theme_red: #ff2d00;
@value theme_pink: #f50057;
@value theme_purple: #6200eb;
/* Breakpoints */

@value mobile: 640px;
@value tablet: 768px;
@value desktop: 1440px;
/* Styles */

a {
    color: text_primary;
}

p {
    text-align: justify;
}

.handwriting {
    font-family: 'ReenieBeanie';
    display: inline;
    font-size: 2.5rem;
    color: text_secondary;
}

.information_text {
    display: inline;
    cursor: default;
    color: text_primary;
    font-size: 2rem;
}

h1.information_text {
    font-size: 3rem;
}

.information_text:hover {
    color: theme_orange;
}

.information_text_inverse {
    composes: information_text;
    color: theme_yellow;
}

.information_text_inverse:hover {
    color: text_primary;
}

@media(max-width:mobile) {
    h1.information_text {
        font-size: 1.8rem;
    }
    .handwriting {
        font-size: 2rem;
    }
    .information_text {
        font-size: 1.7rem;
    }
}