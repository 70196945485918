@value theme_red, theme_green, theme_white from '../../styles/styles.module.css';
.information_paragraph {
    margin-top: 2rem;
}

.italy:hover span:nth-child(3n+1) {
    color: theme_green;
}

.italy:hover span:nth-child(3n+2) {
    color: theme_white;
}

.italy:hover span:nth-child(3n+3) {
    color: theme_red;
}