@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: 'ReenieBeanie';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ReenieBeanie-Regular.ttf');
}

body {
  font-family: 'Ubuntu';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

html, body {
  overflow-x: hidden;
}