/* N.B. This is not a .module.css file, otherwise the parser changes the class name. These styles are used as 
        workaround styles to change the default styles of the VerticalTimelineElement of the library */

.custom-vertical-timeline a {
    color: #848484;
}

.custom-vertical-timeline h4 {
    font-size: 1.4rem;
}

.custom-vertical-timeline h5 {
    font-size: 0.9rem;
    color: #848484;
    margin-bottom: 1rem;
}

.vertical-timeline::before {
    background: #848484;
    width: 1px;
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 20px;
    height: 20px;
    margin-left: -11px;
    margin-top: 22px;
}

@media(max-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        margin-left: 8px;
        margin-top: 15px;
    }
}