@value background_primary from '../styles/styles.module.css';
@value tablet from '../styles/styles.module.css';
.main_container {
    background-color: background_primary;
}

.inner_container {
    width: 80%;
    max-width: 65rem;
}

@media(max-width:tablet) {
    .inner_container {
        width: 100%;
    }
}