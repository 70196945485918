@value text_primary, text_secondary from '../../styles/styles.module.css';
@value tablet from '../../styles/styles.module.css';
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    padding-top: 8rem;
    padding-bottom: 7rem;
}

.card {
    width: 44%;
}

.card a:hover {
    color: text_secondary;
}

@media(max-width:tablet) {
    .container {
        flex-direction: column;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 2rem;
    }
    .card {
        width: 100%;
        margin-bottom: 4rem;
    }
}