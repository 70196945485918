@value text_primary, text_secondary, background_secondary, theme_red, theme_blue, theme_orange, theme_pink, theme_gray, theme_green, theme_white from '../../styles/styles.module.css';
@value mobile, tablet from '../../styles/styles.module.css';
.background_image_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 40%;
    min-height: 25rem;
    max-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile_container {
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: background_secondary;
    margin-top: 19rem;
    padding: 3rem;
    padding-top: 5rem;
}

.image_container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.icons_container {
    display: flex;
    justify-content: center;
}

.info_container {
    width: 70%;
    margin-left: 3rem;
}

@media(max-width:tablet) {
    .background_image_container {
        top: -4rem;
    }
    .profile_container {
        margin-top: 12rem;
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 3%;
    }
    .image_container {
        width: 60%;
        max-width: 20rem;
        height: 25rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
        align-self: center;
    }
    .info_container {
        width: 100%;
        align-self: center;
        margin-left: 0;
    }
}

@media(max-width:mobile) {
    .background_image_container {
        top: -6rem;
    }
    .profile_container {
        margin-top: 10rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .image_container {
        height: 20rem;
    }
}